import React from "react";

class RenderInputBase extends React.Component {
  state = { ts: 0, inputBuffer: "" };

  withoutBorderStyle = {
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  };

  withBorderStyle = {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderTopStyle: "solid",
    borderBottomStyle: "solid",
    borderLeftStyle: "solid",
    borderRightStyle: "solid",
  };

  get styles() {
    return this.props.activeTab?.styleData || {};
  }

  toString(value) {
    return [null, undefined].includes(value)
      ? ""
      : typeof value === "object" && value instanceof Array
      ? value.map((x) => x?.value).join()
      : value?.toString();
  }

  toBoolean(value) {
    return typeof value === "boolean"
      ? value
      : isNaN(value)
      ? !!value
      : !!parseFloat(value);
  }

  get value() {
    return this.state.inputBuffer || this.props.elementDataStore?.value;
  }

  get valueObj() {
    return this.props.elementDataStore;
  }

  onChange(data) {
    this.data = data;
    this.dataChangedAt = Date.now()
    this.props.setElementDataStore({
      ...data,
      valueObj: {
        inputType: this.props.activeTab?.inputType?.group,
        ...(data.valueObj || {}),
      },
    });
  }

  inputBufferTimer1 = null;
  inputBufferTimer2 = null;
  onChangeValue(value) {
    clearTimeout(this.inputBufferTimer1);
    clearTimeout(this.inputBufferTimer2);
    this.setState({ ts: Date.now(), inputBuffer: value });
    this.inputBufferTimer1 = setTimeout(() => {
      this.onChange({ value, valueType: null, valueObj: null });
      this.inputBufferTimer2 = setTimeout(() => {
        this.setState({ inputBuffer: "" });
      }, 100);
    }, 50);
  }

  hoverProps = {
    onMouseEnter: () => this.setState({ hover: true }),
    onMouseLeave: () => this.setState({ hover: false }),
  };

  renderLabel(props = {}, defaultLabel) {
    return (
      <div {...{ style: this.styles?.label, ...props }}>
        {this.props.activeTab?.inputData?.labelText || defaultLabel || ""}
      </div>
    );
  }
}

export default RenderInputBase;
