// import navigationModule from '../../../../Modules/navigation/navigationModule';
import RenderTextInput from "./RenderTextInput";
import { v4 as uuidv4 } from "uuid";

class RenderLocationInput extends RenderTextInput {
  constructor(props) {
    super(props);

    this.API_CALL_INTERVAL = 100;
    this.API_SESSION_TIMEOUT_SECOND = 60;

    this.state = {
      focused: false,
      suggestions: null,
    };
  }

  componentDidUpdate(prevProps) {
    const elementDataStore = this.props.elementDataStore;

    console.log([
      elementDataStore?.value,
      this.data?.value,
      this.processingValue,
    ]);
    if (
      elementDataStore?.value &&
      elementDataStore?.value !== this.data?.value &&
      !this.processingValue &&
      this.dataChangedAt < Date.now() - 200
    ) {
      this.processingValue = elementDataStore?.value;
      this.handleExternal(elementDataStore?.value);
    }
  }

  async handleExternal(value) {
    try {
      console.log("handle external");
      const suggestions = await this.search(value);
      if (suggestions?.[0]) this.onSelect(suggestions[0]);
    } catch (error) {
      console.warn(error);
    }

    this.processingValue = null;
  }

  onChangeValue(value) {
    clearTimeout(this.inputBufferTimer1);
    clearTimeout(this.inputBufferTimer2);
    this.setState({ ts: Date.now(), inputBuffer: value });
    this.inputBufferTimer1 = setTimeout(() => {
      this.props.setElementDataStore({
        value,
        valueType: null,
        valueObj: null,
      });
      this.inputBufferTimer2 = setTimeout(() => {
        this.setState({ inputBuffer: "" });
      }, 100);
    }, 50);
  }

  // navigateToLocationInputScreen() {
  //   navigationModule.navigate('/app/location/search', {
  //     q: this.value,
  //     onChange: data =>
  //       this.onChange({
  //         value: data.description,
  //         valueType: 'location',
  //         valueObj: data,
  //       }),
  //   });
  // }

  timer = null;
  handleSearch(q) {
    clearTimeout(this.timer);
    this.setState({ inputBuffer: q });

    this.timer = setTimeout(
      () => this.getSuggestions(q),
      this.API_CALL_INTERVAL
    );
  }

  session = {
    token: uuidv4(),
    expires: Date.now() + this.API_SESSION_TIMEOUT_SECOND * 1000,
  };

  async search(input) {
    if (this.session.expires < Date.now()) {
      this.session = {
        token: uuidv4(),
        expires: Date.now() + this.API_SESSION_TIMEOUT_SECOND * 1000,
      };
    }

    return this.props.api
      .socket("v1/app/places/placesAutoComplete", {
        input,
        sessiontoken: this.session.token,
      })
      .then((data) => {
        if (data?.status === "OK" && data.predictions) {
          return data.predictions;
        } else {
          throw new Error("Error getting place suggestions");
        }
      })
      .catch((e) => console.error("Error getting place details: " + e.message));
  }

  async getSuggestions(q) {
    if (!q) {
      this.onChange({
        value: "",
        valueType: "location",
        valueObj: null,
      });
      this.setState({ suggestions: null, inputBuffer: null });
    } else {
      const suggestions = await this.search(q).catch((e) => {
        console.warn(e);
        return [];
      });

      this.setState({
        suggestions,
      });
    }
  }

  parseReverseGeocode(geocodeResult) {
    if (!geocodeResult || !geocodeResult.address_components) {
      throw new Error(
        "Invalid geocode result. Ensure address_components exist."
      );
    }

    const addressComponents = geocodeResult.address_components;
    let address = {
      address: geocodeResult?.formatted_address,
      street: "",
      city: "",
      state: "",
      zipcode: "",
      country: "",
    };

    addressComponents.forEach((component) => {
      const types = component.types;

      if (types.includes("street_number")) {
        address.street = component.long_name + " " + address.street;
      }
      if (types.includes("route")) {
        address.street += component.long_name;
      }
      if (types.includes("locality") || types.includes("sublocality")) {
        address.city = component.long_name;
      }
      if (types.includes("administrative_area_level_1")) {
        address.state = component.long_name;
      }
      if (types.includes("postal_code")) {
        address.zipcode = component.long_name;
      }
      if (types.includes("country")) {
        address.country = component.long_name;
      }
    });

    return address;
  }

  async onSelect(x) {
    try {
      console.log("onSelect: ", x);
      this.setState({ inputBuffer: null });

      const placeDetails = await this.props.api.socket(
        "v1/app/places/reverseGeocode",
        {
          place_id: x.place_id,
        }
      );

      this.onChange({
        value: placeDetails.formatted_address || x.description,
        valueType: "location",
        valueObj: {
          placeResult: {
            description: x.description,
            place_id: x.place_id,
            ...placeDetails,
          },
          locationInputValue: this.parseReverseGeocode(placeDetails),
        },
      });
    } catch (error) {
      console.warn(error);
    }
  }

  render() {
    const {
      state: { suggestions, focused },
    } = this;

    return (
      <>
        {this.renderInputTypes({
          inputProps: {
            onFocus: () => this.setState({ focused: true }),
            onBlur: () => {
              setTimeout(() => {
                this.setState({ focused: false });
              }, 50);
            },
            onChange: (e) => this.handleSearch(e.target.value),
          },
        })}
        {focused && suggestions?.length ? (
          <div key="suggestions">
            <div style={styleObj.holder}>
              {suggestions.map((x) => (
                <div
                  key={x.place_id}
                  style={styleObj.description}
                  onClick={() => this.onSelect(x)}
                >
                  <span style={styleObj.pacIcon} />
                  <span>{x.description}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

const styleObj = {
  box: {},
  inputs: {
    border: "0",
    background: "0",
    color: "#848484",
    width: "100%",
    fontSize: "13px",
  },
  holder: {
    position: "absolute",
    margin: "6px 0",
    backgroundColor: "#d8eeff",
    fontSize: "smaller",
    // width: "180px",
    boxShadow: "grey 0px 2px 5px 1px",
    zIndex: 2,
  },
  description: {
    border: "solid 0.5px gray",
    padding: "5px 0px 5px 4px",
    cursor: "pointer",
    color: "black",
    zIndex: 999
  },
  pacIcon: {
    padding: "3px 9px",
    width: "15px",
    height: "20px",
    background:
      "url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png)",
    backgroundPosition: "-1px -160px",
  },
  cover: {
    position: "fixed",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
};

export default RenderLocationInput;
